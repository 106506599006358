// Importing custom font
// @import "/assets/fonts/BasierCircle-Regular/style.css";
// @import "/assets/fonts/BasierCircle-Medium/style.css";

$fontBaseUrl: "assets/fonts/";

// @font-face {
//     font-family:"BasierCircle";
//     src:url($fontBaseUrl + "/BasierCircle-Regular.woff2") format("woff2"),
//         url($fontBaseUrl + "/BasierCircle-Regular.woff") format("woff"),
//         url($fontBaseUrl + "/BasierCircle-Regular.otf") format("opentype");
//     font-style:normal;
//     font-weight:400;
// }
// @font-face {
//     font-family:"BasierCircle";
//     src:url($fontBaseUrl + "/BasierCircle-Regular.woff2") format("woff2"),
//         url($fontBaseUrl + "/BasierCircle-Regular.woff") format("woff"),
//         url($fontBaseUrl + "/BasierCircle-Regular.otf") format("opentype");
//     font-style:normal;
//     font-weight:400;
// }




@font-face {
    font-family: 'basier_circle';
    src: url($fontBaseUrl + 'basiercircle-regular-webfont.woff2') format('woff2'),
    url($fontBaseUrl + 'basiercircle-regular-webfont.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'basier_circle';
    src: url($fontBaseUrl + 'basiercircle-medium-webfont.woff2') format('woff2'),
            url($fontBaseUrl + 'basiercircle-medium-webfont.woff') format('woff');
            font-style: normal;
    font-weight: 500;
}