.react-calendar-timeline .rct-calendar-header {
    border: none;
    height: 80px !important;
}
.react-calendar-timeline .rct-calendar-header > div:first-child {
    height: 50px !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
    font-weight: 500 !important;
}
.react-calendar-timeline .rct-dateHeader {
    background: black;
    color: white;
    border: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 0;
    font-family: "Inter", sans-serif;
    font-size: 12px;
}

.rct-dateHeader .rct-dateHeader-primary {
    height: 50px;
    font-size: 14px;
}

.react-calendar-timeline .rct-sidebar {
    background: white;
    border: none;
}
.rct-sidebar-row {
    font-family: "Inter", sans-serif;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    background-color: white;
    font-size: 12px;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background: none !important;
    padding: 0;
    border: none;
}

.rct-item.control {
    text-align: center;
    font-family: "Inter", sans-serif;
    border: none !important;
    /* height: 27px !important; */
}
.rct-item.control.status- {
    background-color: #C4C4C4 !important;
    color: black !important;
}
.rct-item.control.status-.active {
    background-color: green !important;
}
.rct-item.control.status-approved {
    background-color: #04A357 !important;
}
.rct-item.control.status-disapproved {
    background-color: #e11900 !important;
}


.rct-item.control.status-pending {
    background-color: #ffcf6f !important;
}
/* .react-calendar-timeline .rct-item .rct-item-content {
    line-height: 27px;
} */

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background-color: #f6f6f6;
    border-bottom: rgba(255, 255, 255, .3);
}
.rct-vertical-lines {
    opacity: .2;
}