// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import "../../node_modules/normalize.css/normalize.css";
// Import custom font
@import 'typography.scss';

* {
	box-sizing: border-box;
}

html, body {
	min-height: 100%;
	// padding-bottom: 48px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'basier_circle', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#root,
#root > div {
	height: 100%;
}

ul {
	list-style: none !important;
}
a {
	text-decoration: none;
	color: black;
}